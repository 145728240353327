import AgeRange from 'components/Page/Dashboard/Elders/Filters/AgeRange'
import ElderCity from 'components/Page/Dashboard/Elders/Filters/ElderCity'
import ElderCreationDate from 'components/Page/Dashboard/Elders/Filters/ElderCreationDate'
import Gender from 'components/Page/Dashboard/Elders/Filters/Gender'
import HolocaustSurvivor from 'components/Page/Dashboard/Elders/Filters/HolocaustSurvivor'
import UnderCare from 'components/Page/Dashboard/Elders/Filters/UnderCare'
import City from 'components/Page/Dashboard/Tickets/Filters/City'
import DatesRange from 'components/Page/Dashboard/Tickets/Filters/DatesRange'
import TicketStatus from 'components/Page/Dashboard/Tickets/Filters/TicketStatus'
import TicketTimes from 'components/Page/Dashboard/Tickets/Filters/TicketTimes'
import TicketType from 'components/Page/Dashboard/Tickets/Filters/TicketType'
import Account from 'components/Page/Dashboard/Volunteers/Filters/Account'
import VolunteerCity from 'components/Page/Dashboard/Volunteers/Filters/VolunteerCity'
import VolunteerStatus from 'components/Page/Dashboard/Volunteers/Filters/VolunteerStatus'

export const PAGE_SIZES = [1, 5, 10, 25, 50, 100]
export const INITIAL_PAGE_SIZE = 5

const tables = {
    accounts: {
        visibleKeys: [
            'name',
            'contactName',
            'contactPhone',
            'contactEmail',
            'volunteersCount',
        ],
        keyTypes: {
            name: 'text',
            contactName: 'text',
            contactPhone: 'phone',
            contactEmail: 'email',
            volunteersCount: 'text',
        },
        sortableKeys: {name: 'name', contactName: 'contactName'},
        editableKeys: ['name'],
        requiredKeys: ['name'],
    },
    users: {
        visibleKeys: [
            'name',
            // 'lastName',
            'phone',
            'email',
            // 'address',
            // 'account',
            'status',
        ],
        keyTypes: {
            name: 'fullName',
            // lastName: 'text',
            phone: 'phone',
            email: 'email',
            address: 'address',
            account: 'text',
            status: 'userStatus',
        },
    },
    invites: {
        visibleKeys: ['_id', 'name', 'email', 'created', 'role'],
        keyTypes: {
            _id: 'text',
            name: 'text',
            email: 'email',
            created: 'datetime',
            role: 'text',
        },
    },
    tickets: {
        visibleKeys: [
            'status',
            'elderCity',
            'ticketType',
            'category',
            'availableTimes',
            'created',
            'completedDate',
            'elderName',
            'elderPhone',
            'volunteers',
        ],
        keyTypes: {
            status: 'ticketStatus',
            elderCity: 'elderCity',
            ticketType: 'ticketType',
            category: 'ticketCategory',
            availableTimes: 'ticketTimes',
            created: 'datetime',
            completedDate: 'datetime',
            elderName: 'elderName',
            elderPhone: 'elderPhone',
            volunteers: 'volunteers',
        },
        sortableKeys: {
            status: 'status',
            elderCity: 'elderCity',
            ticketType: 'ticketType',
            category: 'category',
            availableTimes: 'availableTimes.date',
            created: 'created',
            completedDate: 'completedDate',
            elder: 'elder',
            volunteers: 'volunteers',
        },
        filteredKeys: [
            'status',
            'elderCity',
            'ticketType',
            'category',
            'availableTimes',
            'created',
            'completedDate',
        ],
        filterComponents: {
            status: TicketStatus,
            elderCity: City,
            ticketType: TicketType,
            category: TicketType,
            availableTimes: TicketTimes,
            created: () => DatesRange('created'),
            completedDate: () => DatesRange('completedDate'),
        },
    },
    elders: {
        visibleKeys: [
            // '_id',
            'firstName',
            'lastName',
            'phone',
            'birthDate',
            'address',
            'gender',
            'underCare',
            'created',
            'holocaustSurvivor',
        ],
        keyTypes: {
            _id: 'text',
            firstName: 'text',
            lastName: 'text',
            phone: 'phone',
            birthDate: 'age',
            address: 'address',
            gender: 'gender',
            underCare: 'underCare',
            created: 'datetime',
            holocaustSurvivor: 'holocaustSurvivor',
        },
        sortableKeys: {
            firstName: 'firstName',
            lastName: 'lastName',
            phone: 'phone',
            birthDate: 'birthDate',
            address: 'address',
            gender: 'gender',
            underCare: 'underCare',
            created: 'created',
            holocaustSurvivor: 'holocaustSurvivor',
        },
        filteredKeys: [
            'birthDate',
            'address',
            'gender',
            'underCare',
            'created',
            'holocaustSurvivor',
        ],
        filterComponents: {
            birthDate: AgeRange,
            address: ElderCity,
            gender: Gender,
            underCare: UnderCare,
            created: ElderCreationDate,
            holocaustSurvivor: HolocaustSurvivor,
        },
    },
    volunteers: {
        visibleKeys: [
            // '_id',
            'firstName',
            'lastName',
            'email',
            'phone',
            'additionalPhone',
            'gender',
            'address',
            'birthDate',
        ],
        keyTypes: {
            _id: 'text',
            firstName: 'text',
            lastName: 'text',
            email: 'email',
            phone: 'phone',
            additionalPhone: 'phone',
            birthDate: 'date',
            address: 'address',
            gender: 'gender',
        },
        sortableKeys: {
            firstName: 'firstName',
            lastName: 'lastName',
            email: 'email',
            phone: 'phone',
            additionalPhone: 'additionalPhone',
            gender: 'gender',
            address: 'address',
            birthDate: 'birthDate',
        },
    },
    social_workers: {
        visibleKeys: [
            'firstName',
            'lastName',
            'email',
            'phone',
            'gender',
            'region',
            'subRegion',
        ],
        keyTypes: {
            firstName: 'text',
            lastName: 'text',
            email: 'email',
            phone: 'phone',
            gender: 'gender',
            region: 'text',
            subRegion: 'longText',
        },
        sortableKeys: {
            firstName: 'firstName',
            lastName: 'lastName',
            email: 'email',
            phone: 'phone',
            gender: 'gender',
            region: 'region',
            subRegion: 'subRegion',
        },
    },
    eldersTickets: {
        visibleKeys: [
            'status',
            'startAddress',
            'ticketType',
            'availableTimes',
            'created',
            'volunteers',
        ],
        keyTypes: {
            status: 'ticketStatus',
            startAddress: 'ticketAddress',
            ticketType: 'ticketType',
            availableTimes: 'ticketTimes',
            created: 'datetime',
            volunteers: 'volunteers',
        },
        sortableKeys: {
            status: 'status',
            startAddress: 'startAddress',
            ticketType: 'ticketType',
            availableTimes: 'availableTimes',
            created: 'created',
            volunteers: 'volunteers',
        },
    },
    users_volunteers: {
        visibleKeys: [
            'name',
            'phone',
            'email',
            'address',
            'organization',
            'status',
            'created',
        ],
        keyTypes: {
            name: 'fullName',
            phone: 'phone',
            email: 'email',
            address: 'address',
            organization: 'organization',
            status: 'userStatus',
            created: 'date',
        },
        filteredKeys: ['address', 'account', 'status', 'created'],
        filterComponents: {
            address: VolunteerCity,
            account: Account,
            status: VolunteerStatus,
            created: () => DatesRange('created'),
        },
        sortableKeys: {created: 'created'},
    },
    users_admins: {
        visibleKeys: ['firstName', 'lastName', 'nationalId', 'phone', 'email'],
        keyTypes: {
            firstName: 'text',
            lastName: 'text',
            nationalId: 'text',
            phone: 'phone',
            email: 'email',
        },
        sortableKeys: [],
    },
    users_specialists: {
        visibleKeys: ['firstName', 'lastName', 'nationalId', 'phone', 'email'],
        keyTypes: {
            firstName: 'text',
            lastName: 'text',
            nationalId: 'text',
            phone: 'phone',
            email: 'email',
        },
    },
    users_social_workers: {
        visibleKeys: [
            'firstName',
            'lastName',
            'email',
            'phone',
            'gender',
            'region',
            'subRegion',
        ],
        keyTypes: {
            firstName: 'text',
            lastName: 'text',
            email: 'email',
            phone: 'phone',
            gender: 'gender',
            region: 'text',
            subRegion: 'longText',
        },
        sortableKeys: {
            firstName: 'firstName',
            lastName: 'lastName',
            email: 'email',
            phone: 'phone',
            gender: 'gender',
            region: 'region',
            subRegion: 'subRegion',
        },
    },
    users_relatives: {
        visibleKeys: ['firstName', 'lastName', 'phone', 'email'],
        keyTypes: {
            firstName: 'text',
            lastName: 'text',
            phone: 'phone',
            email: 'email',
        },
    },
    organizations: {
        visibleKeys: [
            'name',
            'contactName',
            'contactPhone',
            'contactEmail',
            'city',
            'numberOfVolunteers',
            'link',
        ],
        keyTypes: {
            name: 'text',
            numberOfVolunteers: 'text',
            link: 'copyClipboardIcon',
        },
        editableKeys: ['name'],
        requiredKeys: ['name'],
    },
}

export default tables
