import {useI18n, useGetUsersQuery} from 'store/Store'
import {DataGrid} from '@mui/x-data-grid'
import {useMemo} from 'react'
import {styled} from '@mui/material'
import {calculateDistanceInMeters} from 'services/utils/locationUtils'
import {timeSince} from 'services/utils/dateUtils'

const SendTicketToVoulnteersMessageStep = ({form, ticket}) => {
    const {i18n} = useI18n()
    const {
        data: volunteers,
        isFetching,
        isError,
    } = useGetUsersQuery({
        type: 'volunteer',
        'address.city': ticket.startAddress?.city || ticket.elderCity,
        allowWhatsAppInquiries: true,
    })
    if (isError) {
        return (
            <ErrorMessage>
                {i18n.t('sendToVolunteersForm.failedToFetchVolunteers')}
            </ErrorMessage>
        )
    }
    return (
        <VolunteersTable
            ticket={ticket}
            volunteers={isFetching ? [] : volunteers.data}
            loading={isFetching}
            form={form}
        />
    )
}

const VolunteersTable = ({volunteers, loading, form, ticket}) => {
    const {i18n} = useI18n()
    const columns = useMemo(
        () => [
            {
                field: 'fullName',
                headerName: i18n.t('sendToVolunteersForm.fullName'),
                sortable: false,
                width: 160,
                valueGetter: (params) =>
                    `${params.row.firstName || ''} ${
                        params.row.lastName || ''
                    }`,
            },
            {
                field: 'lastWhatsappMessage',
                headerName: i18n.t('users.lastWhatsappMessage'),
                sortable: true,
                type: 'date',
                valueFormatter: (params) =>
                    params.value ? timeSince(new Date(params.value)) : '-',
                width: 160,
            },
            {
                field: 'distance',
                headerName: i18n.t('users.distance'),
                sortable: true,
                type: 'number',
                valueGetter: (params) =>
                    calculateDistanceInMeters(
                        params.row.address,
                        (ticket.startAddress?.lat
                            ? ticket.startAddress
                            : undefined) || ticket.elder.address
                    ),
                width: 160,
            },
            {
                field: 'created',
                headerName: i18n.t('users.creationDate'),
                sortable: true,
                type: 'date',
                valueFormatter: (params) =>
                    new Date(params.value).toLocaleDateString(),
                width: 160,
            },
            {
                field: 'address',
                headerName: i18n.t('volunteers.address'),
                sortable: false,
                width: 160,
                valueGetter: (params) =>
                    `${params.row.address?.street || ''} ${
                        params.row.address?.city || ''
                    }`,
            },
        ],
        [i18n, ticket.startAddress, ticket.elder.address]
    )

    return (
        <DataGrid
            loading={loading}
            density="compact"
            getRowId={(row) => row._id}
            rows={volunteers}
            columns={columns}
            initialState={{
                sorting: {
                    sortModel: [
                        {field: 'distance', sort: 'desc'},
                        {field: 'lastWhatsappMessage', sort: 'asc'},
                    ],
                },
                pagination: {
                    paginationModel: {
                        pageSize: 10,
                    },
                },
            }}
            autoHeight
            pageSizeOptions={[5]}
            checkboxSelection
            sortingMode="client"
            paginationMode="client"
            onRowSelectionModelChange={(selectedVolunteers) => {
                form.setValue(
                    'volunteers',
                    selectedVolunteers.map((vId) =>
                        volunteers.find((v) => v._id === vId)
                    )
                )
            }}
        />
    )
}

const ErrorMessage = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-block: 12px;
`

export default SendTicketToVoulnteersMessageStep
