import {DialogContent} from '@mui/material'
import Button from 'components/Control/Button'
import Dialog from 'components/Display/DialogForm/Dialog'
import {useState} from 'react'
import {useForm} from 'react-hook-form'
import {useI18n, useSendMessagesMutation} from 'store/Store'
import styled from 'styled-components'
import SendTicketToVoulnteersMessageStep from './SendTicketToVolunteersMessageStep'
import SendTicketToVolunteersPickVolunteersStep from './SendTicketToVolunteersPickVolunteersStep'
import SendTicketToVolunteersSendMessagesStep from './SendTicketToVolunteersSendMessagesStep'

const SendTicketToVoulnteers = ({ticket, open, onClose}) => {
    const {i18n} = useI18n()
    const [step, setStep] = useState('messageTemplate')
    const [
        doSendMessages,
        {
            error: sendError,
            data: sendResponse,
            isLoading: sendingMessages,
            reset,
        },
    ] = useSendMessagesMutation()
    const onCloseDialog = (...args) => {
        setStep('messageTemplate')
        form.reset()
        reset()
        onClose(...args)
    }
    const form = useForm({
        defaultValues: {
            details: ticket.detailsComments,
            subject: i18n.t(`tickets.${ticket.subCategory}`),
            volunteers: [],
        },
        mode: 'all',
    })
    const hasRepsone = sendError || sendResponse
    const onSubmit = (data) => {
        if (step === 'messageTemplate') {
            setStep('pickVolunteers')
            return
        }
        if (step === 'pickVolunteers') {
            setStep('sendMessages')
            return
        }
        if (step === 'sendMessages' && !sendError && !sendResponse) {
            doSendMessages({
                volunteers: data.volunteers,
                helpRequest: {
                    ticketId: ticket._id,
                    subject: data.subject,
                    details: data.details,
                },
            })
            return
        }
        onCloseDialog()
    }
    function btnTextByStep() {
        if (sendingMessages) {
            return i18n.t('sendToVolunteersForm.sendingMessages')
        }
        if (step === 'messageTemplate') {
            return i18n.t('sendToVolunteersForm.pickVolunteers')
        }
        if (step === 'pickVolunteers') {
            return i18n.t('sendToVolunteersForm.continue')
        }
        if (step === 'sendMessages') {
            if (hasRepsone) {
                return i18n.t('sendToVolunteersForm.close')
            }

            return i18n.t('sendToVolunteersForm.sendMessages')
        }
    }
    return (
        <Dialog
            open={open}
            onClose={onCloseDialog}
            title={i18n.t('ticketMenu.sendToVolunteers')}>
            <DialogContent>
                {step === 'messageTemplate' ? (
                    <SendTicketToVoulnteersMessageStep
                        ticket={ticket}
                        form={form}
                    />
                ) : null}
                {step === 'pickVolunteers' ? (
                    <SendTicketToVolunteersPickVolunteersStep
                        ticket={ticket}
                        form={form}
                    />
                ) : null}
                {step === 'sendMessages' ? (
                    <SendTicketToVolunteersSendMessagesStep
                        ticket={ticket}
                        form={form}
                        error={sendError}
                        response={sendResponse}
                    />
                ) : null}
                <DialogActions>
                    <Button color="secondary" onClick={onCloseDialog}>
                        {i18n.t('form.cancel')}
                    </Button>
                    <Button
                        onClick={form.handleSubmit(onSubmit)}
                        disabled={!form.formState.isValid || sendingMessages}>
                        {btnTextByStep()}
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default SendTicketToVoulnteers

const DialogActions = styled('div')`
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
`
