import FormTextInput from 'components/Control/Form/FormTextInput'
import TextArea from 'components/Control/Form/TextArea'
import SendTicketToVolunteerMessagePreview from './SendTicketToVolunteerMessagePreview'
import {useI18n} from 'store/Store'
const SendTicketToVoulnteersMessageStep = ({form, ticket}) => {
    const {i18n} = useI18n()
    const {
        control,
        formState: {errors},
        watch,
    } = form

    const detailsValue = watch('details')
    const subjectValue = watch('subject')

    return (
        <>
            <FormTextInput
                control={control}
                name="subject"
                label={i18n.t('sendToVolunteersForm.subject')}
                rules={{required: i18n.t('form.required')}}
                error={errors?.subject}
            />
            <TextArea
                control={control}
                name="details"
                label={i18n.t('sendToVolunteersForm.details')}
                rules={{required: i18n.t('form.required')}}
                error={errors?.details}
            />
            <SendTicketToVolunteerMessagePreview
                ticket={ticket}
                subject={subjectValue}
                detials={detailsValue}
            />
        </>
    )
}

export default SendTicketToVoulnteersMessageStep
