import {styled} from '@mui/material'
import {CenteredFormErrorText} from 'components/Control/Form'
import FormChipSelect from 'components/Control/Form/FormChipSelect'
import FormTextInput from 'components/Control/Form/FormTextInput'
import {
    paymentTypes,
    ticketTypeCategories,
    ticketTypes,
} from 'constants/tickets'
import {useFormContext} from 'react-hook-form'
import {setValueOptions} from 'services/utils/formUtils'
import {
    showLocationInputs,
    showSelectAssistance,
} from 'services/utils/validations/validationUtils'
import {useI18n} from 'store/Store'
import TicketTabSection from '../Tickets/TicketTabSection'
import TicketIconsSection from './components/TicketIconsItem'
import TicketFormError from './TicketFormError'
import FormAddressComponent from 'components/Control/Form/FormAddressComponent'

const TicketDetails = () => {
    const {i18n} = useI18n()
    const formContext = useFormContext()
    const {
        control,
        setValue,
        watch,
        formState: {errors: formErrors},
    } = formContext
    const values = watch()
    const {category, subCategory} = values

    const handleSelectItem = (fieldName, selectedItem) => {
        if (fieldName === 'subCategory') {
            const newValue = showSelectAssistance(category, selectedItem)
                ? []
                : undefined

            setValue('subCategoryOptions', newValue, setValueOptions)
        }
        if (fieldName === 'category') {
            setValue('subCategory', undefined, setValueOptions)
        }

        setValue(fieldName, selectedItem, setValueOptions)
    }

    const handleSetValue = (fieldName) => (newValues) => {
        setValue(fieldName, newValues, setValueOptions)
    }

    const getOptionLabel = (key) => i18n.t(`tickets.${key}`)

    const setAddressValue = (address) => {
        formContext.setValue(
            'categoryLocationAddress',
            {...address},
            setValueOptions
        )
    }

    return (
        <Container>
            <TicketTabSection
                testKey={'category'}
                title={i18n.t('tickets.ticket_details')}
                subtitle={i18n.t('tickets.ticket_type')}>
                <TicketIconsSection
                    itemName="ticket"
                    fieldName="category"
                    types={ticketTypeCategories}
                    handleSelectItem={handleSelectItem}
                    activeItem={values.category}
                />

                {formErrors.category && (
                    <CenteredFormErrorText>
                        {formErrors.category.message}
                    </CenteredFormErrorText>
                )}
            </TicketTabSection>

            {category && (
                <TicketTabSection
                    testKey={'subCategory'}
                    title={i18n.t(`tickets.${category}_title`)}
                    subtitle={i18n.t('tickets.type')}>
                    <TicketIconsSection
                        itemName="ticket"
                        fieldName="subCategory"
                        types={Object.keys(ticketTypes[category])}
                        handleSelectItem={handleSelectItem}
                        activeItem={subCategory}
                    />
                    {showSelectAssistance(category, subCategory) && (
                        <FormChipSelect
                            itemName="ticket"
                            name="subCategoryOptions"
                            label={i18n.t('ticketForm.assistance_type')}
                            control={formContext.control}
                            errors={formErrors}
                            fullWidth
                            options={ticketTypes[category][subCategory]}
                            handleChange={handleSetValue('subCategoryOptions')}
                            getOptionLabel={getOptionLabel}
                            style={{marginInline: 'auto', width: '35%'}}
                        />
                    )}

                    {showLocationInputs(category, subCategory) && (
                        <LocationInputsContainer>
                            <FormTextInput
                                name="categoryLocationName"
                                label={i18n.t('ticketForm.locationName')}
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                fullWidth
                                errors={formErrors}
                            />
                            <FormAddressComponent
                                itemName="users"
                                name="categoryLocationAddress"
                                mainInputName="address"
                                label={i18n.t('ticketForm.address')}
                                control={control}
                                errors={formErrors}
                                setAddressValue={setAddressValue}
                            />
                            <FormTextInput
                                name="categoryLocationWorkingHours"
                                label={i18n.t('ticketForm.workingHours')}
                                control={control}
                                fullWidth
                                errors={formErrors}
                            />
                        </LocationInputsContainer>
                    )}

                    {formErrors.subCategory && (
                        <CenteredFormErrorText>
                            {formErrors.subCategory.message}
                        </CenteredFormErrorText>
                    )}
                </TicketTabSection>
            )}

            <TicketTabSection
                title={i18n.t('tickets.paymentType')}
                testKey={'paymentType'}>
                <TicketIconsSection
                    itemName="ticket"
                    fieldName="paymentType"
                    types={paymentTypes}
                    handleSelectItem={handleSelectItem}
                    activeItem={values.paymentType}
                />

                <TicketFormError
                    fieldName="paymentType"
                    errors={formErrors}
                    shouldShowError={formErrors.paymentType}
                />
            </TicketTabSection>

            <TicketTabSection
                title={i18n.t('tickets.notesForVolunteer_details')}>
                <FormTextInput
                    name="detailsComments"
                    label={i18n.t('ticketForm.importantNotes')}
                    control={control}
                    rules={{
                        required: true,
                    }}
                    fullWidth
                    errors={formErrors}
                    inputProps={{maxLength: 500}}
                />
            </TicketTabSection>
        </Container>
    )
}

export default TicketDetails

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 20px;
`
const LocationInputsContainer = styled('div')`
    display: flex;
    gap: 16px;
    width: 100%;
    margin-block: 24px;
    & > * {
        flex: 1;
    }
`
