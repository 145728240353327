import {Slide} from '@mui/material'
import {styled} from '@mui/material/styles'
import {Colors} from 'appearance'
import Button from 'components/Control/Button'
import Dialog from 'components/Display/DialogForm/Dialog'
import {ticketTabs} from 'constants/tickets'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {isAdmin} from 'services/utils/misc'
import {useDeleteTicketMutation} from 'store/slices/api/ticketsApi'
import {useGetCurrentUserQuery, useI18n} from 'store/Store'
import TicketDeleteAlert from './TicketDeleteAlert'
import WarningIcon from '@mui/icons-material/Warning'
import TicketDialog from 'screens/TicketDialog'

const transitionDuration = 200

const TicketDetailes = ({ticket, closeTicketDetails}) => {
    const {i18n} = useI18n()
    const navigate = useNavigate()
    const {data: user} = useGetCurrentUserQuery()
    const [doDeleteTicket] = useDeleteTicketMutation()
    const tabs = Object.keys(ticketTabs)
    const [selectedTab, setSelectedTab] = useState(tabs[0])
    const [openDeleteAlert, setOpenDeleteAlert] = useState(false)
    const [ticketId, setTicketId] = useState()

    const handleChange = (tab) => (event) => {
        setSelectedTab(tab)
    }

    const renderTab = () => {
        const Component = ticketTabs[selectedTab]
        return <Component ticket={ticket} />
    }

    const toggleDeleteAlert = () => {
        setOpenDeleteAlert(!openDeleteAlert)
    }

    const handleDelete = () => {
        toggleDeleteAlert()
    }

    const appoveDelete = () => {
        onDelete()
    }

    const onDelete = () => {
        doDeleteTicket(ticket._id)
        closeTicketDetails()
    }

    const onEdit = () => {
        navigate(`/ticket/${ticket._id}`)
    }

    return (
        <>
            <Slide in={true} timeout={transitionDuration} direction="right">
                <Container>
                    <TabsContainer>
                        {tabs.map((tab) => (
                            <TabButton
                                data-testid={`${tab}_button`}
                                disableRipple
                                key={tab}
                                active={(tab === selectedTab).toString()}
                                onClick={handleChange(tab)}>
                                {i18n.t(`ticketInfoTabs.${tab}`)}
                            </TabButton>
                        ))}
                    </TabsContainer>
                    <TabContent>{renderTab()}</TabContent>
                    <TicketActions multiple={isAdmin(user)}>
                        {isAdmin(user) && (
                            <Button
                                variant="text"
                                color="error"
                                onClick={handleDelete}>
                                {i18n.t('tickets.deleteTicketButton')}
                            </Button>
                        )}
                        <div style={{display: 'flex', gap: 8}}>
                            <Button
                                color="dark"
                                onClick={() => setTicketId(ticket._id)}
                                data-testid="ticket_modal">
                                {i18n.t('tickets.ticketDetailsButton')}
                            </Button>
                            <Button onClick={onEdit} data-testid="edit_button">
                                {i18n.t('form.edit')}
                            </Button>
                        </div>
                    </TicketActions>
                </Container>
            </Slide>
            <Dialog
                titlePadding="12px 12px 0 12px"
                open={openDeleteAlert}
                onClose={toggleDeleteAlert}
                title={<WarningIcon color="error" fontSize="large" />}>
                <TicketDeleteAlert
                    onClose={toggleDeleteAlert}
                    onOk={appoveDelete}
                />
            </Dialog>

            <Dialog
                maxWidth="xl"
                onClose={() => setTicketId(null)}
                open={!!ticketId}>
                <TicketDialog ticketId={ticketId} />
            </Dialog>
        </>
    )
}

export default TicketDetailes

const Container = styled('div')`
    flex: 2;
    display: flex;
    flex-direction: column;
    height: fit-content;
    position: sticky;
    top: 0;
`

const TabsContainer = styled('div')`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
`

const TabButton = styled(Button)`
    background-color: ${({active}) =>
        active === 'true' ? Colors.APP_GRAY : Colors.APP_WHITE};
    padding: 4px 12px;
    color: ${Colors.PRIMARY_TEXT};
    width: 100%;
    overflow: hidden;
    min-width: fit-content;
    border-radius: 0;
    &:hover {
        background: transparent;
    }
`

const TabContent = styled('div')`
    background-color: ${Colors.APP_GRAY};
`
const TicketActions = styled('div')`
    display: flex;
    justify-content: ${({multiple}) =>
        multiple ? 'space-between' : 'flex-end'};
    align-items: center;
    padding: 16px 0;
`
