const he = {
    dir: 'rtl',
    header: {
        search: 'חיפוש',
        newTicketButton: 'פניה חדשה',
        logout: 'התנתק',
    },
    auth: {
        phone: 'טלפון',
        firstName: 'שם פרטי',
        lastName: 'שם משפחה',
        code: 'קוד',
        welcome: 'ברוכים הבאים ל-',
        sendCode: 'שלחו קוד',
        didntReceive: 'לא קיבלת קוד?',
        resendCode: 'שלח שוב',
        noAccess:
            'גישה לא מורשית. אנא צור קשר עם המשרד של 20-80 כדי לקבל גישה למערכת.',
        login: 'התחבר',
        otpExpiresIn: 'קוד פג תוקף בעוד:',
        timesUp: 'פג תוקף הקוד, לחץ כדי לשלוח קוד חדש',
    },
    menu: {
        dashboard: 'לוח בקרה',
        animals: 'חיות',
        all_mail: 'דוא״ל',
        trash: 'זבל',
        spam: 'ספאם',
        users: 'משתמשים',
        accounts: 'חשבונות',
        elders: 'זקנים',
    },
    form: {
        update: 'עדכון',
        create: 'יצירת',
        link: 'קישור',
        add: 'הוספת',
        keyError: 'נדרש/ת {{key}} חוקי/ת.',
        not: 'לא',
        saveAndContinue: 'שמור והמשך',
        cancel: 'ביטול',
        ok: 'אישור',
        back: 'חזור אחורה',
        goBack: 'חזור',
        continue: 'המשך',
        edit: 'עריכה',
        saveAndClose: 'שמור וסגור',
        autocompleteNoOptionsText: 'אין תוצאות',
        updateDetails: 'עדכן פרטים',
        delete: 'מחק',
        deleteUser: 'מחק משתמש',
        sendInvite: 'שלח הזמנה למשתמש חדש',
        copy: 'העתק',
    },
    general: {
        invalid_password: 'סיסמה חייבת להיות בת 8 תוים לפחות',
        invalid_verify_password: 'הסיסמה לא תואמת את שדה הסיסמה הראשון',
        dayAndMonth: '{{day}} ב{{month}}',
        clearDateFilter: 'נקה תאריכים',
        clearRangeFilter: 'נקה טווח',
        clearAllFilters: 'נקה פילטרים',
    },
    tables: {
        users_volunteers: 'ניהול משתמשים',
        users_admins: 'ניהול משתמשים',
        users_specialists: 'ניהול משתמשים',
        users_social_workers: 'ניהול משתמשים',
        users_relatives: 'ניהול משתמשים',
        invites: 'מוזמנים',
        accounts: 'חשבונות',
        organizations: 'אירגונים',
        elders: 'ותיקים',
        tickets: 'היסטוריית פניות',
        volunteers: 'מתנדבים',
        social_workers: 'עובדים סוציאליים',
        addButtonText: 'הוספת {{type}}',
    },
    accounts: {
        account: 'חשבון',
        _id: 'ID',
        name: 'שם ארגון',
        apiKey: 'Api Key',
        created: 'נוצר',
        updated: 'נערך',
        role: 'תפקיד',
        confirmed: 'מאושר',
        contactName: 'איש קשר',
        contactPhone: 'מספר ליצירת קשר',
        contactEmail: 'אימייל ליצירת קשר',
        volunteersCount: 'מספר מתנדבים',
        dialogTitle: 'הוספת חשבון',
        dialogTitleEdit: 'עריכת חשבון',
        accountDetailsDialogTitle: 'ניהול חשבון {{name}}',
        inviteLinkTitle: 'לינק להזמנת משתמשים',
        cannotDeleteError:
            'קיימים מתנדבים תחת {{name}} עם פניות פתוחות. לא ניתן למחוק את החשבון בשלב זה.',
    },
    organizations: {
        organization: 'אירגון',
        name: 'שם האירגון',
        dialogTitleEdit: 'עריכת ארגון',
        dialogTitle: 'הוספת ארגון',
        numberOfVolunteers: 'מספר מתנדבים רשומים',
        contactName: 'איש קשר',
        contactPhone: 'מספר ליצירת קשר',
        contactEmail: 'אימייל ליצירת קשר',
        city: 'עיר',
        link: 'קישור להזמת מתנדבים',
        copyLinkToClipboard: 'קישור הזמנה למתנדב הועתק בהצלחה',
    },
    users: {
        _id: 'ID',
        name: 'שם',
        email: 'דוא״ל',
        created: 'נוצר',
        role: 'תפקיד',
        firstName: 'שם פרטי',
        lastName: 'שם משפחה',
        phone: 'טלפון',
        additionalPhone: 'טלפון נוסף',
        gender: 'מגדר',
        genders: {
            MALE: 'גבר',
            FEMALE: 'אישה',
            OTHER: 'אחר',
        },
        allowWhatsAppInquiries: 'קבלת הודעות ווטסאפ',
        address_title: 'כתובת',
        address: {
            houseNumber: "מס' בית",
            street: 'רחוב',
            city: 'עיר',
            floor: 'קומה',
            apartmentNumber: 'דירה',
            enterance: 'כניסה',
            steetHouseNumberAndApartment: "רחוב, מס' בית ועיר",
        },
        birthDate: 'תאריך לידה',
        year: 'שנה (yyyy)',
        month: 'חודש (mm)',
        day: 'יום (dd)',
        underCare: 'מטופל/ת ברווחה',
        underCares: {
            YES: 'כן',
            NO: 'לא',
            UNKNOWN: 'לא ידוע',
        },
        comments: 'הערות',
        elder: 'ותיק/ה',
        social_worker: 'עו"ס',
        volunteer: 'מתנדב',
        relative: 'קרוב משפחה',
        other: 'אחר',
        dialogTitle: 'הוספת {{type}}',
        dialogTitleEdit: 'עריכת {{type}}',
        region: 'איזור פעילות (עיר)',
        regions: {
            TEL_AVIV: 'תל-אביב',
            HAIFA: 'חיפה',
            BEER_SHEVA: 'באר-שבע',
            LOD: 'לוד',
            NATANYA: 'נתניה',
            HEDERA: 'חדרה',
        },
        subRegion: 'איזור פעילות (ספציפית)',
        volunteers: 'מתנדבים',
        admins: 'מנהלי מערכת',
        specialists: 'מוקדנים',
        social_workers: 'עו"ס',
        relatives: 'קרובי משפחה',
        status: 'סטטוס',
        statuss: {
            registered: 'רשום',
            unverified: 'מחכה לאימות',
            suspended: 'מושעה',
            verified: 'מאומת',
            pending_support: 'צריך סיוע',
        },
        relationship: 'סוג הקשר',
        relationships: {
            legal_guardian: 'אפרוטופוס/ית',
            child: 'ילד/ילדה',
            spouse: 'בן/בת זוג',
            grandchild: 'נכד/ה',
            sibling: 'אח/ות',
            other: 'אחר',
        },
        creationDate: 'תאריך יצירת משתמש במערכת',
        lastWhatsappMessage: 'הודעה אחרונה נשלחה לפני',
        distance: 'מרחק מהפנייה',
        primaryAddress: 'כתובת התנדבות ראשית',
        secondaryAddress: 'כתובת התנדבות משנית',
        account: 'חשבון',
        accounts: 'שיוך לחשבון',
        deleteConfirmationText: 'בטוח שברצונך למחוק את {{name}} מהמערכת?',
        cannotDeleteError: {
            volunteer:
                'ל{{name}} מוצמדות פניות פתוחות. לא ניתן למחוק את המשתמש',
            specialist:
                'ל{{name}} קיימות פניות עתידיות. לא ניתן למחוק את המשתמש',
        },
        holocaustSurvivor: ' שורד/ת שואה',
        holocaustSurvivors: {
            YES: 'כן',
            NO: 'לא',
            UNKNOWN: 'לא ידוע',
        },
        admin: 'מנהל מערכת',
        nationalId: 'מספר זהות',
        roles: {
            USER: 'מוקדן',
            ADMIN: 'מנהל מערכת',
        },
        specialist: 'מוקדן',
        userTypes: {
            elder: 'ותיק',
            social_worker: 'עו"ס',
            volunteer: 'מתנדב',
            relative: 'קרוב משפחה',
            other: 'אחר',
            specialist: 'מוקדן',
        },
        roleType: 'תפקיד',
        officePhone: 'טלפון משרד',
        mobilePhone: 'טלפון נייד',
        user_already_exists: 'משתמש קיים במערכת',
        organization: 'שיוך לארגון',
    },
    // organizations: {
    //     _id: 'ID',
    //     created: 'נוצר',
    //     updated: 'נערך',
    //     description: 'תיאור',
    //     name: 'שם',
    //     order: 'סדר',
    //     address: 'כתובת',
    //     area: 'איזור',
    //     contact: 'איש קשר',
    //     email: 'דוא״ל',
    //     facebookUrl: 'קישור פייסבוק',
    //     homeUrl: 'אתר הבית',
    //     isActive: 'פעיל',
    //     lastActive: 'פעילות אחרונה',
    //     lastAdminMsg: 'הודעת אדמין אחרונה',
    //     logoUrl: 'לוגו',
    //     password: 'סיסמא',
    //     phone: 'טלפון',
    //     times: 'זמנים',
    //     username: 'שם משתמש',
    // },
    elders: {
        elder: 'ותיק',
        _id: 'ID',
        firstName: 'שם פרטי',
        lastName: 'שם משפחה',
        phone: 'טלפון',
        birthDate: 'גיל',
        address: 'כתובת',
        gender: 'מגדר',
        underCare: 'מטופל/ת ברווחה',
        created: 'נוצר',
        holocaustSurvivor: ' שורד/ת שואה',
        filters: {
            ageRange: 'טווח גילאים',
            range_from: 'מ-',
            range_to: 'עד',
        },
    },
    tickets: {
        ticket: 'פניה',
        ticketTimeEnded: 'זמן הפניה פג',
        create_ticket_description:
            'הקלד את שם ו/או מספר הטלפון של הותיק לחיפוש',
        results_title: 'תוצאות ({{count}})',
        search_no_results:
            'לא נמצאו תוצאות לפי הערכים שהקלדת. רשום את הזקן ולאחר מכן, המשך לפתיחת פניה',
        createTicket: 'צור פניה',
        ticketDateReceived: 'התקבלה ביום {{date}}',
        name: 'name',
        address: 'כתובת',
        ticketDetails: 'פרטי הפניה',
        ticketTime: 'מועד הפניה',
        ticketRoute: 'מסלול הפניה',
        summary: 'סיכום',
        draft: 'טיוטה',
        waitingForVolunteer: 'ממתין למתנדב',
        preCoordination: 'לפני תיאום',
        preMeeting: 'לפני פגישה',
        city_hall: 'עירייה',
        social_worker: 'עו"ס',
        volunteer: 'מתנדב',
        relative: 'קרוב משפחה',
        friend: 'חבר/ה',
        social_networks: 'רשתות חברתיות',
        flyer: 'קיבלו פלאייר',
        sms: 'SMS',
        HMO: 'קופת חולים',
        other: 'אחר',
        dispatcher_name: 'שם המוקדן/ית',
        new_ticket: 'פתיחת פניה חדשה',
        ticket_source: 'נא לבחור את מקור הפניה',
        how_did_you_hear_about_us: 'איך שמעו עלינו',
        search_exising: 'חיפוש {{item}} קיים',
        issuerSources: {
            otherOrganization: 'ארגון אחר',
            ministryForSocialEquality: 'המשרד לשוויון חברתי',
            friend: 'חבר/ה',
            neighbor: 'שכן/ה',
            careGiver: 'מטפל/ת',
            other: 'אחר',
        },
        ticket_details: 'פרטי הפניה',
        ticket_type: 'סוג הפניה',
        indoor_assistance: 'סיוע בתוך הבית',
        indoor: 'בתוך הבית',
        phone_assistance: 'סיוע טלפוני',
        escort: 'ליווי',
        delivery: 'משלוח',
        digital: 'סיוע דיגיטלי',
        social: 'מפגש חברתי',
        nursing_care: 'ביקור חולים',
        change_lightbulb: 'החלפת נורה',
        paper_organization: 'סידור מסמכים',
        gardening: 'גינון קל',
        moving_furnitur: 'הזזת רהיטים',
        climb_ladder: 'טיפוס על סולם',
        dog_walk: 'טיול עם הכלב',
        general: 'עזרה כללית',
        guidance: 'הדרכה',
        appointment: 'קביעת תורים',
        forms: 'מילוי טפסים',
        translation: 'תרגום',
        coffee: 'קפה',
        game: 'משחק',
        walkAroundTheHouse: 'טיול ליד הבית',
        type: 'סוג',
        remote_assistance: 'סיוע מרחוק',
        conversation: 'שיחה להפגת בדידות',
        medical_escort: 'ליווי רפואי',
        doctor: 'רופא',
        checkups: 'בדיקות',
        surgery: 'ניתוח',
        vaccine: 'חיסון',
        shopping_escort: 'ליווי לקניות',
        groceries: 'מצרכים',
        medicines: 'תרופות',
        equipment: 'ציוד',
        social_escort: 'ליווי חברתי',
        play: 'הצגה',
        concert: 'הופעה',
        postoffice: 'דואר',
        shopping_delivery: 'משלוח קניות',
        small: 'קניה קטנה',
        large: 'קניה גדולה',
        medicines_delivery: 'משלוח תרופות',
        food_delivery: 'משלוח סל מזון',
        equipment_delivery: 'משלוח ציוד',
        electric_equipment: 'מכשיר חשמלי',
        medical: 'מכשיר רפואי',
        furniture: 'רהיט',
        indoor_title: 'סיוע בתוך הבית',
        phone_assistance_title: 'סיוע טלפוני',
        escort_title: 'ליווי מחוץ לבית',
        delivery_title: 'משלוח',
        paymentType: 'אופן התשלום',
        noPayment: 'לא נדרש תשלום',
        cash: 'מזומן',
        bit: 'ביט',
        bankTransfer: 'העברה בנקאית',
        coupon: 'תווי קניה',
        notesForVolunteer_details: 'הערות חשופות למתנדב (פרטי הפניה)',
        notesForVolunteer_route: 'הערות חשופות למתנדב (מסלול הפניה)',
        notesInternal: 'הערות פנימיות',
        summary_notes_details: 'הערות פרטי פניה',
        summary_notes_route: 'הערות מסלול פניה',
        ticketRoutePickup_title: 'ממקום',
        ticketRouteDropoff_title: 'למקום',
        ticketRouteReturn_title: 'ובחזרה',
        ticketRouteTransport_title: 'דרך התניידות בפניה',
        elderHome: 'בית הותיק',
        otherLocation: 'מקום אחר',
        none: 'אין צורך',
        walk: 'רגלית',
        public: 'תחבורה ציבורית',
        taxi: 'מונית',
        car: 'מכונית',
        ticketSummary: 'סיכום הפניה',
        ticketNumber: 'מספר הפניה',
        dispatcherName: 'שם המוקדן/ית',
        ticketDateAndTime: 'תאריך ושעת הפניה',
        ticketReason: 'סיבת הפניה',
        ticketStatus: 'סטטוס פניה',
        ticketRequestor: 'מבקש הפניה',
        ticketNotes: 'הערות',
        ticketType: 'סיווג פניה',
        category: 'סוג פניה',
        ticketTypes: {
            urgent: 'דחופה',
            regular: 'רגילה',
            weekly: 'שבועית',
            future: 'עתידית',
        },
        relationship: 'סוג הקשר',
        relationships: {
            legal_guardian: 'אפרוטופוס/ית',
            child: 'ילד/ילדה',
            spouse: 'בן/בת זוג',
            grandchild: 'נכד/ה',
            sibling: 'אח/ות',
            other: 'אחר',
        },
        statuss: {
            draft: 'טיוטה',
            waitingForVolunteer: 'ממתינות למתנדב',
            preCoordination: 'לפני תיאום',
            preMeeting: 'לפני מפגש',
            canceled: 'בוטלה',
            completed: 'הושלמה',
        },
        deleteTicketButton: 'מחיקת פניה',
        ticketDetailsButton: 'פרטים מלאים',
        status: 'סטטוס',
        startAddress: 'עיר',
        created: 'תאריך יצירת פניה',
        elder: 'פרטי הותיק',
        availableTimes: 'תאריך ביצוע הפניה',
        deleteTicketAlertTitle: 'מחיקת פניה',
        deleteTicketAlertDescription: 'האם את/ה בטוח/ה שברצונך למחוק את הפניה?',
        asignVolunteerToTicket: 'הצמדת מתנדב לפניה',
        unassignVolunteer: 'הסרת מתנדב מהפניה',
        volunteers: 'פרטי מתנדבים',
        elderName: 'שם הותיק',
        elderPhone: "מס' טלפון של הותיק",
        cancellationInitiator: 'מי ביטל?',
        cancellationInitiators: {
            elder: 'הותיק/ה',
            call_center: 'המוקד',
            volunteer: 'המתנדב',
        },
        cancellationReason: 'סיבת הביטול',
        cancellationReasons: {
            volunteerNotFound: 'לא נמצא מתנדב בזמן',
            solvedBeforeVolunteer: 'הסתדר לפני שנמצא מתנדב',
            solvedAfterVolunteer: 'הסתדר אחרי שנמצא מתנדב',
            notRelevant: 'הפניה לא רלוונטית',
            notWorkingOut: 'לא מסתדר לי כרגע',
            other: 'אחר',
        },
        sendAnotherVolunteer: 'צריך לשלוח משהו במקומך',
        yes: 'כן',
        no: 'לא',
        filters: {
            status: 'סטטוס',
            cancellationInitiator: 'מקור ביטול',
            cancellationReason: 'סיבת ביטול',
            ticketTime: 'טווח תאריכים',
        },
        creator: 'יוצר הפניה',
        ticketSource: 'מקור הפניה',
        subCategory: 'תת קטגוריה',
        importantNotes: 'הערות חשובות',
        volunteerDetails: 'פרטי המתנדב',
        volunteerName: 'שם המתנדב',
        account: 'חשבון',
        feedback: 'פידבק',
        elderCity: 'עיר',
        feedback_question_contact_details: 'האם השאיר פרטי קשר',
        feedback_question_meaningfule_meetup: 'פגישה משמעותית',
        feedback_question_community_engagement: 'מעורבות בקהילה',
        feedback_question_opinions_change: 'שינוי דעה בנושא עבודה עם ותיקים',
        feedback_question_free_text: 'טקסט חופשי',
        feedback_answers: {
            yes: 'כן',
            no: 'לא',
            good: 'משנה לטובה',
            bad: 'משנה לרעה',
            none: 'לא משנה',
        },
        phoneNumber: 'מספר טלפון',
        completedDate: 'תאריך השלמת הפניה',
    },
    ticketInfoTabs: {
        elderDetails: 'פרטי הזקן',
        ticketDetails: 'פרטי הפניה',
        volunteerDetails: 'פרטי המתנדב',
        feedback: 'פידבק',
    },
    ticketForm: {
        ticketNumber: 'מספר פניה',
        issuer: 'מבקש הפניה',
        startAddress: 'איסוף',
        destinationAddress: 'יעד',
        endAddress: 'נקודת סיום',
        notes: 'נקודות חשובות',
        name: 'שם',
        gender: 'מין',
        address: 'כתובת',
        formTitle: 'פרטים ראשונים',
        createElder: 'פניה עבור ותיק/ה חדש',
        status: 'סטטוס פניה',
        category: 'סוג הפניה',
        type: 'סוג',
        markerColor: 'מרקר פנייה',
        ticketSource_choose_source: 'בחר את מקור הפניה',
        ticketSources: {
            name: 'שם האדם/ארגון',
            phone: 'טלפון ליצירת קשר',
        },
        assistance_type: 'בחירת סיוע',
        locationName: 'שם מקום היעד',
        workingHours: 'שעות פעילות',
        importantNotes: 'דברים שחשוב לדעת (עד 500 תווים)',
        ticketDuratioTitle: 'זמנים',
        ticketDuratioSubtitle: 'זמן ביצוע מוערך',
        ticketDuration_other_label: 'זמן אחר (שעות)',
        ticketDurations: {
            15: 'רבע שעה',
            30: 'חצי שעה',
            60: 'שעה',
            90: 'שעה וחצי',
            120: 'שעתיים',
            other: 'אחר',
        },
        ticketTime_title: 'מועד הפניה',
        ticketTimes: {
            custom: 'מועד ספציפי',
            '24_hours': 'ב-24 שעות הקרובות',
            '3_days': 'ב-3 ימים הקרובים',
            '7_days': 'ב-7 ימים הקרובים',
            week_or_more: 'בעוד שבוע ומעלה',
        },
        ticketTimes_custom_date: 'תאריך',
        ticketTimes_custom_time: 'שעה',
        dayTimeSlots: {
            morning: 'בוקר',
            noon: 'צהריים',
            evening: 'ערב',
            morning_hours: '8:00 עד 12:00',
            noon_hours: '12:00 עד 16:00',
            evening_hours: '16:00 עד 20:00',
        },
        day: 'יום {{day}}',
        available: 'זמין',
        floor: 'קומה',
        apartment: 'דירה',
        entrance: 'כניסה',
        saveTicket: 'הפץ פניה',
        subCategory: 'תת קטגוריה',
    },
    ticketMenu: {
        editTicket: 'עריכת פניה',
        editVolunteer: 'שינוי מתנדבים',
        changeTicketStatus: 'שינוי סטטוס פניה',
        sendToVolunteers: 'הפצת פנייה',
        setMarkerColor: 'מרקר פניה',
        deleteTicket: 'מחיקת פניה',
        copyToClipboardTicketUrl: 'העתקת קישור לפניה',
        copiedToClipboard: 'קישור לפניה הועתק בהצלחה',
    },
    sendToVolunteersForm: {
        subject: 'נושא הפנייה',
        details: 'פרטים',
        pickVolunteers: 'בחירת מתנדבים',
        fullName: 'שם מלא',
        lastMessagesCount: 'מספר הודעות נשלחו ב4 ימים אחרונים',
        continue: 'המשך',
        sendMessages: 'שליחת הודעות',
        sendingMessages: 'שולח...',
        status: 'סטטוס שליחה',
        close: 'סגור',
        success: 'נשלח',
        'volunteer-not-found': 'מתנדב לא קיים ברשימת ווטסאפ',
        'failed-to-send-chatbot-conversation': 'שליחת הודעה נכשלה',
        failedToFetchVolunteers: 'אירעה שגיאה בטעינת המתנדבים, אנא נסה שנית',
    },
    volunteers: {
        volunteer: 'מתנדב',
        firstName: 'שם פרטי',
        lastName: 'שם משפחה',
        phone: 'טלפון',
        additionalPhone: 'טלפון נוסף',
        gender: 'מגדר',
        email: 'דוא״ל',
        address: 'כתובת',
        birthDate: 'תאריך לידה',
        addVolunteerTitle: 'הוספת מתנדב',
        editVolunteerTitle: 'עריכת מתנדב',
    },
    social_workers: {
        social_worker: 'עו"ס',
        firstName: 'שם פרטי',
        lastName: 'שם משפחה',
        phone: 'טלפון נייד',
        additionalPhone: 'טלפון משרד',
        gender: 'מגדר',
        email: 'דוא״ל',
        addSocialWorkerTitle: 'הוספת עו"ס',
        editSocialWorkerTitle: 'עריכת עו"ס',
        region: 'איזור פעילות (עיר)',
        subRegion: 'איזור פעילות (ספציפית)',
        genders: {
            MALE: 'גבר',
            FEMALE: 'אישה',
            OTHER: 'אחר',
        },
        regions: {
            TEL_AVIV: 'תל-אביב',
            HAIFA: 'חיפה',
            BEER_SHEVA: 'באר-שבע',
            LOD: 'לוד',
            NATANYA: 'נתניה',
            HEDERA: 'חדרה',
        },
    },
    eldersTickets: {
        status: 'סטטוס',
        startAddress: 'עיר',
        ticketType: 'סיווג פניה',
        availableTimes: 'תאריך ביצוע הפניה',
        created: 'תאריך יצירת פניה',
        volunteers: 'פרטי מתנדבים',
    },
    users_volunteers: {
        users_volunteer: 'מתנדב',
        name: 'שם מתנדב',
        email: 'אימייל',
        phone: 'מספר טלפון',
        address: 'כתובת התנדבות ראשית',
        account: 'חשבון',
        status: 'סטטוס',
        updateConfirmationText: 'בטוח שברצונך לעדכן את המתנדב?',
        created: 'תאריך הצטרפות',
        organization: 'ארגון',
    },
    users_admins: {
        users_admin: 'מנהל מערכת',
        firstName: 'שם פרטי',
        lastName: 'שם משפחה',
        email: 'אימייל',
        phone: 'מספר טלפון',
        nationalId: 'מספר זהות',
        updateConfirmationText: 'בטוח שברצונך לעדכן את המשתמש?',
    },
    users_specialists: {
        users_specialist: 'מוקדן',
        firstName: 'שם פרטי',
        lastName: 'שם משפחה',
        email: 'אימייל',
        phone: 'מספר טלפון',
        nationalId: 'מספר זהות',
        updateConfirmationText: 'בטוח שברצונך לעדכן את המשתמש?',
    },
    users_social_workers: {
        users_social_worker: 'עו"ס',
        firstName: 'שם פרטי',
        lastName: 'שם משפחה',
        email: 'אימייל',
        phone: 'מספר טלפון',
        social_worker: 'עו"ס',
        additionalPhone: 'טלפון משרד',
        gender: 'מגדר',
        region: 'איזור פעילות (עיר)',
        subRegion: 'איזור פעילות (ספציפית)',
        updateConfirmationText: 'בטוח שברצונך לעדכן את המשתמש?',
    },
    users_relatives: {
        users_relative: 'קרוב משפחה',
        firstName: 'שם פרטי',
        lastName: 'שם משפחה',
        email: 'אימייל',
        phone: 'מספר טלפון',
        nationalId: 'מספר זהות',
        updateConfirmationText: 'בטוח שברצונך לעדכן את המשתמש?',
    },
}

export default he
