import {CircularProgress, InputAdornment} from '@mui/material'
import {i18n, React} from 'common'
import {FormInput} from 'components/Control/Form'
import {useUrlParams} from 'services/hooks'

const TableSearch = ({itemName, useSearch, isLoading, ...p}) => {
    const {urlParams, setUrlParam} = useUrlParams()
    const {[`${itemName}_search`]: search} = urlParams

    if (!useSearch) {
        return null
    }

    const handleChange = (event) => {
        setUrlParam(`${itemName}_search`, event.target.value)
        setUrlParam(`${itemName}_page`, 0)
    }

    return (
        <div {...p}>
            <FormInput
                name={`${itemName}Search`}
                onChange={handleChange}
                value={search}
                placeholder={`${i18n.t('header.search')}...`}
                InputProps={{
                    endAdornment: isLoading && (
                        <InputAdornment position="start">
                            <CircularProgress size={20} />
                        </InputAdornment>
                    ),
                    disableUnderline: true,
                }}
            />
        </div>
    )
}

export default TableSearch
