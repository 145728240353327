import {renderHook} from '@testing-library/react/pure'
import {Provider} from 'react-redux'
import store from 'store/Store'
import React from 'react'
import dotenv from 'dotenv'
dotenv.config({path: '.env'})

export const testWrapper = ({children}) => (
    <Provider store={store}>{children}</Provider>
)

export const activateHook = async (useHook) => {
    const {result, rerender} = renderHook(useHook, {
        wrapper: testWrapper,
    })

    return {result, rerender}
}

export const isTestEnv = () => process.env.NODE_ENV === 'test'
