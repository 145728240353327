import TableCellRaw from '@mui/material/TableCell'
import {useI18n, useIsRtl} from 'store/Store'
import {formatKeyFunctionsByType} from 'services/utils/tables'
import {styled} from '@mui/material'

const TableRow = ({itemKey, itemId, tableRowData, keyTypes, keyActions}) => {
    const isRtl = useIsRtl()
    const {i18n} = useI18n()
    const value = tableRowData[itemKey]
    const type = keyTypes?.[itemKey]

    const formattedValue = formatKeyFunctionsByType[type]?.(
        value,
        i18n,
        tableRowData
    )

    const displayValue =
        formattedValue || formattedValue === 0 || formattedValue === ''
            ? formattedValue
            : value

    return (
        <StyledTableCell
            align={isRtl ? 'right' : 'left'}
            component="th"
            scope="row">
            {keyActions?.[itemKey] ? (
                <Action onClick={() => keyActions?.[itemKey]?.(itemId)}>
                    {displayValue}
                </Action>
            ) : (
                displayValue
            )}
        </StyledTableCell>
    )
}

export default TableRow

const Action = styled('span')`
    color: blue;
    cursor: pointer;
`

const StyledTableCell = styled(TableCellRaw)`
    & .long-text-cell {
        display: block;
        width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`
