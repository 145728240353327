import {getFullAddress, getFullName} from 'services/utils/formUtils'
import {Colors, Texts} from 'appearance'
import styled from 'styled-components'
import TabContentRow from './TabContentRow'
import {ticketIcons} from 'constants/tickets'
import moment from 'moment'
import {useI18n} from 'store/Store'
import {RenderTicketNotes} from '../../Ticket/TicketSummary'

const TicketDetails = ({ticket}) => {
    const {i18n} = useI18n()
    const {
        created,
        issuer,
        ticketNumber,
        startAddress,
        destinationAddress,
        endAddress,
    } = ticket

    const number = 1234567
    const issuerName = getFullName(issuer)
    const start = getFullAddress(startAddress) || '-'
    const destination = getFullAddress(destinationAddress) || '-'
    const end = getFullAddress(endAddress) || '-'
    const date = moment(created).format('dddd DD.MM.YY H:mm')
    const translateValue = (value) => i18n.t(`tickets.${value}`)

    return (
        <Container>
            <DateTexts>
                {i18n.t('tickets.ticketDateReceived', {date})}
            </DateTexts>

            <TabContentRow
                rowData={ticketNumber || number}
                rowKey="ticketNumber"
                showDivider={true}
            />

            <TabContentRow
                rowData={translateValue(ticket.category)}
                rowKey="category"
                showDivider={true}
            />

            <TabContentRow
                rowData={translateValue(ticket.subCategory)}
                rowKey="subCategory"
                showDivider={true}
            />

            <TabContentRow
                rowData={issuerName}
                rowKey="issuer"
                showDivider={true}
            />

            {['escort', 'delivery'].includes(ticket.category) && (
                <>
                    <TabContentRow
                        rowData={start}
                        rowKey="startAddress"
                        showDivider={true}
                        icon={ticketIcons.startAddress}
                    />
                    <TabContentRow
                        rowData={destination}
                        rowKey="destinationAddress"
                        showDivider={true}
                        icon={ticketIcons.destinationAddress}
                    />
                    <TabContentRow
                        rowData={end}
                        rowKey="endAddress"
                        showDivider={true}
                        icon={ticketIcons.endAddress}
                    />
                </>
            )}
            <TabContentRow
                rowData={
                    <RenderTicketNotes
                        detailsComments={ticket?.detailsComments}
                        routeComments={ticket?.routeComments}
                        internalComments={ticket?.internalComments}
                    />
                }
                rowKey="notes"
                showDivider={false}
            />
        </Container>
    )
}

export default TicketDetails

const Container = styled('div')`
    position: relative;
`

const DateTexts = styled(Texts.RegularText)`
    font-size: 10px;
    position: absolute;
    color: ${Colors.APP_GRAY_LIGHT};
    top: 7px;
    left: 7px;
`
