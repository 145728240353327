import {Divider, styled} from '@mui/material'
import {Texts} from 'appearance'
import Button from 'components/Control/Button'
import FormSelect from 'components/Control/Form/FormSelect'
import Dialog from 'components/Display/DialogForm/Dialog'
import {ticketClassification} from 'constants/tickets'
import {useState} from 'react'
import {useFormContext} from 'react-hook-form'
import {useGetCurrentUserQuery, useI18n} from 'store/Store'
import TicketTabSection from '../Tickets/TicketTabSection'
import FormTextInput from 'components/Control/Form/FormTextInput'
import {getFullName} from 'services/utils/formUtils'
import {isAdmin} from 'services/utils/misc'

const TicketSummary = () => {
    const {i18n} = useI18n()
    const formContext = useFormContext()
    const {data: user} = useGetCurrentUserQuery()
    const values = formContext.watch()

    const creatorName = getFullName(user)
    const issuerName = getFullName(values?.issuer, values?.issuerName)

    const [editNotes, setEditNotes] = useState(false)
    const [editTicketType, setEditTicketType] = useState(false)

    const toggleEditTicketType = () => {
        setEditTicketType(!editTicketType)
    }

    const toggleEditNotes = () => {
        setEditNotes(!editNotes)
    }

    const handleUpdateTicketType = () => {
        toggleEditTicketType()
    }

    return (
        <Container>
            <TicketTabSection title={i18n.t('tickets.ticketSummary')}>
                <SummaryItem name="dispatcherName">
                    <Subtitle>{i18n.t('tickets.dispatcherName')}</Subtitle>
                    <Text>{creatorName}</Text>
                    <Divider style={{width: '100%'}} />
                </SummaryItem>

                <SummaryItem name="ticketReason">
                    <Subtitle>{i18n.t('tickets.ticketReason')}</Subtitle>
                    <Text>
                        {values?.category &&
                            i18n.t(`tickets.${values.category}`)}
                    </Text>
                    <Divider style={{width: '100%'}} />
                </SummaryItem>

                <SummaryItem name="ticketStatus">
                    <Subtitle>{i18n.t('tickets.ticketStatus')}</Subtitle>
                    <Text>
                        {values?.status && i18n.t(`tickets.${values.status}`)}
                    </Text>
                    <Divider style={{width: '100%'}} />
                </SummaryItem>

                <SummaryItem name="ticketType">
                    <SubtitleWithButtonWrapper>
                        <Subtitle>{i18n.t('tickets.ticketType')}</Subtitle>
                        {isAdmin(user) && (
                            <Button
                                name={'edit'}
                                variant="text"
                                disableRipple
                                onClick={toggleEditTicketType}>
                                {i18n.t('form.edit')}
                            </Button>
                        )}
                    </SubtitleWithButtonWrapper>
                    <Text>
                        {values?.ticketType &&
                            i18n.t(`tickets.ticketTypes.${values.ticketType}`)}
                    </Text>
                    <Divider style={{width: '100%'}} />
                </SummaryItem>

                <SummaryItem name="ticketRequestor">
                    <Subtitle>{i18n.t('tickets.ticketRequestor')}</Subtitle>
                    <Text>{issuerName}</Text>
                    <Divider style={{width: '100%'}} />
                </SummaryItem>

                <SummaryItem name="ticketNotes">
                    <SubtitleWithButtonWrapper alignstart={editNotes}>
                        <Subtitle noWrap>
                            {i18n.t('tickets.ticketNotes')}
                        </Subtitle>
                        <Button
                            name="edit"
                            variant="text"
                            disableRipple
                            onClick={toggleEditNotes}>
                            {i18n.t('form.edit')}
                        </Button>
                    </SubtitleWithButtonWrapper>
                    {editNotes ? (
                        <>
                            <FormTextInput
                                label={i18n.t(
                                    'tickets.notesForVolunteer_details'
                                )}
                                name="detailsComments"
                                control={formContext.control}
                                fullWidth
                                errors={formContext.formState.errors}
                                inputProps={{maxLength: 500}}
                            />
                            <FormTextInput
                                label={i18n.t(
                                    'tickets.notesForVolunteer_route'
                                )}
                                name="routeComments"
                                control={formContext.control}
                                fullWidth
                                errors={formContext.formState.errors}
                                inputProps={{maxLength: 500}}
                            />
                            <FormTextInput
                                label={i18n.t('tickets.notesInternal')}
                                name="internalComments"
                                control={formContext.control}
                                fullWidth
                                errors={formContext.formState.errors}
                                inputProps={{maxLength: 500}}
                            />
                        </>
                    ) : (
                        <RenderTicketNotes
                            detailsComments={values?.detailsComments}
                            routeComments={values?.routeComments}
                            internalComments={values?.internalComments}
                        />
                    )}
                </SummaryItem>
            </TicketTabSection>

            <Dialog
                open={editTicketType}
                onClose={toggleEditTicketType}
                title={i18n.t('tickets.ticketType')}>
                <DialogContent>
                    <FormSelect
                        itemName="tickets"
                        name="ticketType"
                        label={i18n.t('tickets.ticketType')}
                        control={formContext.control}
                        rules={{
                            required: true,
                        }}
                        fullWidth
                        initialValue=""
                        errors={formContext.formState.errors}
                        options={ticketClassification}
                    />

                    <DialogActions>
                        <Button
                            name="cancel"
                            color="secondary"
                            onClick={toggleEditTicketType}>
                            {i18n.t('form.cancel')}
                        </Button>
                        <Button
                            onClick={handleUpdateTicketType}
                            name="saveAndContinue">
                            {i18n.t('form.saveAndContinue')}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </Container>
    )
}

export default TicketSummary

export const RenderTicketNotes = ({
    detailsComments,
    routeComments,
    internalComments,
}) => {
    const {i18n} = useI18n()

    const details = detailsComments ? `${detailsComments}` : ''
    const route = routeComments ? `${routeComments}` : ''
    const internal = internalComments ? `${internalComments}` : ''
    if (!details.length && !route.length && !internalComments?.length) {
        return '-'
    }
    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <CommentsWrapper component="div">
                <Text fontWeight="bold">
                    {i18n.t('tickets.summary_notes_details')}:{' '}
                </Text>
                {details}
            </CommentsWrapper>
            <CommentsWrapper component="div">
                <Text fontWeight="bold">
                    {i18n.t('tickets.summary_notes_route')}:{' '}
                </Text>
                {route}
            </CommentsWrapper>
            <CommentsWrapper component="div">
                <Text fontWeight="bold">
                    {i18n.t('tickets.notesInternal')}:{' '}
                </Text>
                {internal}
            </CommentsWrapper>
        </div>
    )
}

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const SummaryItem = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-block: 12px;
`

const SubtitleWithButtonWrapper = styled('div')`
    display: flex;
    align-items: ${({alignstart}) => (alignstart ? 'start' : 'center')};
    gap: 4px;
    & > * {
        font-size: 15px;
        font-weight: normal;
        padding: 0;
    }
    & > *:hover {
        background: transparent;
    }
`

const Subtitle = styled(Texts.RegularText)`
    font-size: 15px;
    font-weight: 300;
`
const Text = styled(Texts.RegularText)`
    font-size: 15px;
`

const CommentsWrapper = styled(Text)`
    word-break: break-all;
`

const DialogContent = styled('div')`
    padding: 16px 24px;
`

const DialogActions = styled('div')`
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
`
