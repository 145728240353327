import {React} from 'common'
import Table from 'components/Display/Table'
import {Page, PageContent} from 'components/Page'
import {
    useDeleteOrganizationMutation,
    useGetOrganizationsQuery,
    useGetOrganizationQuery,
    useI18n,
} from 'store/Store'
import {FormProvider, useForm} from 'react-hook-form'
import Dialog from 'components/Display/DialogForm/Dialog'
import {useCallback, useEffect, useState} from 'react'
import {isNew} from 'services/utils/formUtils'
import {organizationSchema} from 'services/utils/validations/validationSchemas'
import {yupResolver} from '@hookform/resolvers/yup'
import Account from './Account'
import ConfirmationDialog from 'components/Display/ConfirmationDialog'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb'
import ErrorDialog from 'components/Display/ErrorDialog'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

const getId = (organizationId, deleteOrganization, organizationDialogId) =>
    organizationId || deleteOrganization || organizationDialogId

//TODO: this is basically the same as accounts - make them one component
const Organizations = () => {
    const {i18n} = useI18n()
    const [organizationId, setOrganizationId] = useState()
    const [deleteOrganization, setDeleteOrganization] = useState()
    const [cannotDeleteOrganization, setCannotDeleteOrganization] = useState()
    const [cannotDeleteMsg, setCannotDeleteMsg] = useState()

    const id = getId(organizationId, deleteOrganization)

    const {data: organization} = useGetOrganizationQuery(id, {
        skip: isNew(id),
    })
    const [doDeleteOrganization] = useDeleteOrganizationMutation()

    const clearOrganizationId = useCallback(() => {
        setOrganizationId(null)
    }, [])

    const methods = useForm({
        defaultValues: {...organization},
        mode: 'all',
        resolver: yupResolver(organizationSchema),
    })
    const {reset} = methods

    useEffect(() => {
        if (organization && id && id !== 'new') {
            reset({...organization})
        } else {
            reset({})
        }
    }, [organization, reset, id])

    const onDeleteOrganization = (organizationId) => {
        setDeleteOrganization(organizationId)
    }

    const handleDeleteOrganization = async () => {
        try {
            const deleteResponse = await doDeleteOrganization(
                deleteOrganization
            )
            console.log('deleteResponse', deleteResponse, organization)
            setDeleteOrganization()
            clearOrganizationId()

            if (deleteResponse?.error) {
                setCannotDeleteMsg(deleteResponse.error?.data?.message)
                setCannotDeleteOrganization(deleteOrganization)
                return
            }
        } catch (error) {
            console.log('delete organization error', {error})
        }
    }

    return (
        <Page menuTitle={'organizations'}>
            <PageContent>
                <Table
                    itemName="organization"
                    allowEdit
                    allowDelete
                    deletePrompt
                    allowAdd
                    addFunction={() => setOrganizationId('new')}
                    editFunction={setOrganizationId}
                    paginationType="external"
                    useGetQuery={useGetOrganizationsQuery}
                    useDeleteMutation={useDeleteOrganizationMutation}
                    deleteFunction={onDeleteOrganization}
                />

                <FormProvider {...methods}>
                    <Dialog
                        onClose={clearOrganizationId}
                        open={
                            (!!organizationId && !!organization) ||
                            organizationId === 'new'
                        }
                        title={i18n.t(
                            `${
                                isNew(organizationId)
                                    ? 'organizations.dialogTitle'
                                    : 'organizations.dialogTitleEdit'
                            }`
                        )}>
                        <Account
                            objectId={organizationId}
                            handleClose={clearOrganizationId}
                            onDeleteObject={onDeleteOrganization}
                            objectType={'organization'}
                        />
                    </Dialog>
                </FormProvider>

                <ConfirmationDialog
                    open={!!deleteOrganization && organization}
                    text1={organization}
                    text={i18n.t(`users.deleteConfirmationText`, {
                        name: organization?.name,
                    })}
                    onOk={handleDeleteOrganization}
                    okButtonOptions={{
                        text: i18n.t('form.delete'),
                        icon: <DoNotDisturbIcon />,
                        color: 'error',
                    }}
                    handleClose={() => setDeleteOrganization(null)}
                />

                <ErrorDialog
                    open={!!cannotDeleteOrganization}
                    text={cannotDeleteMsg}
                    handleClose={() => {
                        setCannotDeleteOrganization(null)
                        setCannotDeleteMsg(null)
                    }}
                    buttonOptions={{
                        icon: <KeyboardArrowRightIcon />,
                        color: 'secondary',
                    }}
                />
            </PageContent>
        </Page>
    )
}

export default Organizations
