export function timeSince(date) {
    let seconds = Math.floor((new Date() - date) / 1000)

    let interval = Math.floor(seconds / 31536000)

    if (interval > 1) {
        return interval + ' שנים'
    }
    interval = Math.floor(seconds / 2592000)
    if (interval > 1) {
        return interval + ' חודשים'
    }
    interval = Math.floor(seconds / 86400)
    if (interval > 1) {
        return interval + ' ימים'
    }
    interval = Math.floor(seconds / 3600)
    if (interval > 1) {
        return interval + ' שעות'
    }
    interval = Math.floor(seconds / 60)
    if (interval > 1) {
        return interval + ' דקות'
    }
    return Math.floor(seconds) + ' שניות'
}
